import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import Testimonial from "../components/testimonial"
import Resolutions from "../components/resolutions"
import TextBanner from "../components/textBanner"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const ResolutionPage = ({data}) => {
  const seoInfo = data.datoCmsResolutionPage
  return(
  <Layout>
    <Seo description={seoInfo.metaDescription} title={seoInfo.metaTitle} />
    
    <Introduction title="Resolution Pathways" subtitle={data.datoCmsResolutionPage.resolutionPathways} caption={data.datoCmsResolutionPage.resolutionPathwaysSmallText} /> 
    
    <Resolutions resolutions={data.allDatoCmsResolution} /> 
  
    <TextBanner title="All Pathways Lead to a Document" text={data.datoCmsResolutionPage.allPathwaysLeadToADocument}/>
    <Testimonial background="#ECECEC" author={data.datoCmsResolutionPage.testimonialName} text={data.datoCmsResolutionPage.testimonialQuotation} />

  </Layout>)
}

export const query = graphql`
  query {
    datoCmsResolutionPage{
      testimonialName
      testimonialQuotation
      resolutionPathwaysSmallText
    resolutionPathways
    allPathwaysLeadToADocument
    metaDescription
    metaTitle
    
    }
    allDatoCmsResolution {
      edges {
        node {
          name
          backgroundColor {
            rgb
          }
          image {
            gatsbyImageData
          }
          content
        }
      }
    }
  }
`

export default ResolutionPage
