import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import Arrow from "./partials/arrow"  
import { Link } from "gatsby"

const Resolution = ({resolutions}) =>{
    const allResolution = resolutions.edges?.map((resolution, index) => {
        return ( 
        <div key={index} className="single-resolution p20" style={{backgroundColor:resolution.node.backgroundColor?.rgb}}>
            <div className="max-80p m-mwa ma mt40 mb40 m-m0 m-mt20 m-mb20">
                <div className="icon max-100">
                    <div className="ratio-1-1 pos-rel">
                        <GatsbyImage image={resolution.node.image?.gatsbyImageData} alt="Resolution Icon" className="bg-image contain" /> 
                    </div>
                </div>
                <p className="h3 normal m0">{resolution.node?.name}</p>
                <div className="content mt20 sainte">
                    <div className="copy mb40 prewrap" dangerouslySetInnerHTML={{ __html: resolution.node?.content }}></div>
                </div>
                <Link className="link large flex align-center" to="/contact">Enquire <Arrow /></Link> 
            </div>
        </div>
        )
    });
    return(
        <div className="resolutions--all p20 mp0" style={{backgroundColor:'#ECECEC'}}>
            <div className="max-1400 ma grid grid-2 m-no-gap">
                {allResolution}
            </div>
        </div>
    )
}
export default Resolution